.DownloadPayWrapper{
    background-image: url('../../assets/images/downloadback.svg');
}
.download-wrapper .right{
  
    flex: 0 1 304px;
    margin-top: -140px;
}
.download-wrapper .right img{
   width: 100%;
}
.download-wrapper .left{
 
   flex-wrap: 0  1 420px;
   align-self: center;
   padding-top: 20px;
}
.download-wrapper .left > p{
   font-size: 20px;
   margin: 10px 0;
}
.download-wrapper .left > div p{
   font-weight: 500;
   margin-bottom: 8px;
}

@media only screen and (max-width: 767px){
   .download-wrapper  {
      flex-direction: column;
      padding-top: 20px;
      gap: 0;
   }
   .download-wrapper .right{
      margin: 20px auto 0;
   }
   .download-wrapper > div{
      flex: 0 1 auto;
   }
}