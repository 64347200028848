.consumerWrapper {
    background-color: #fff;
    padding-bottom: 120px;
}

.consumerWrapper .img {

    flex: 0 1 424px;
}

.consumerWrapper .desc {

    flex: 0 1 460px;

}

.consumerWrapper .img img {
    width: 100%;
}

.consumerWrapper .consumerBottom {
    background-color: #F9F9F9;
    margin-bottom: 8px;
}

.consumerWrapper .consumer-bottom .img {
    margin-bottom: -30px;
}

@media only screen and (max-width: 767px) {

    .consumerWrapper .consumer-top,
    .consumerWrapper .consumer-bottom {
        flex-direction: column-reverse;
        gap: 20px !important;

    }

    .consumerWrapper .consumer-top>div,
    .consumerWrapper .consumer-bottom>div {
        max-width: 400px;
        margin: auto;
        flex: 0 1 auto;
    }

    .consumerWrapper .consumer-top>.desc {
        padding-top: 20px;
    }

    .consumerWrapper .consumer-top.consumer-middle {
        flex-direction: column;
    }


}