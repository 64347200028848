.businessWrapper {
    background-color: #fff;
    padding: 40px 0;
}

.businessWrapper .top {
    max-width: 860px;
    margin: auto;
}

.businessWrapper .top p {
    font-size: 20px;
}

.businessWrapper .card-box {
    flex-wrap: wrap;
    margin-top: 40px;
}

.businessWrapper .bottom {
    max-width: 860px;
    padding: 30px 0;
    margin: auto;

}

.businessWrapper .card-box .card {
    width: 30%;
    min-height: 297px;
    background-color: var(--section-gbColor-V1);
    padding: 60px 40px 40px;
}

.businessWrapper .card-box .card h3 {
    margin-bottom: 6px;
}


@media only screen and (max-width: 992px) {
    .businessWrapper .card-box .card {
        width: 45%;

    }

}

@media only screen and (max-width: 767px) {
    .businessWrapper .card-box .card {
        width: 100%;
        max-width: 360px;

    }

    .businessWrapper {
        padding: 20px 0;
    }
}