.serviceswrapper{
   background-color: #fff;
}
.serviceswrapper .img{
  
   flex: 0 1 424px;
}
.serviceswrapper .desc{

   flex: 0 1 460px;
  
}
.serviceswrapper .img img{
   width: 100%;
}
.serviceswrapper .consumerBottom{
   background-color: #F9F9F9;
   margin-bottom: 8px;
}

.serviceswrapper .consumer-bottom .img{
   margin-bottom: -30px;
}
.desc p{
   font-size: 20px;
   text-align: justify;
   
}
.serviceswrapper .middle > div{
    flex: 0 1 540px;
}
.bg-color{
    background-color: var(--section-gbColor-V1);
}

.serviceswrapper .top{
    max-width: 860px;
    margin: auto;
}

.serviceswrapper .top p{
    font-size: 20px;
}

.serviceswrapper .card-box{
    flex-wrap: wrap;
    margin-top: 40px;
 }
 .serviceswrapper .bottom{
   
    padding: 60px 0;
    margin: auto;
    
 }
 .serviceswrapper .card-box  .card{
     flex: 0 1 22%;
     min-height: 297px;
     background-color: var(--section-gbColor-V1);
     padding: 40px  20px  20px;
 }
 .serviceswrapper .card-box  .img{
    width: 93px;
    height: 93px;
    background-color: #fff;
    box-shadow: 0px 1px 2px #00000012;
    border-radius: 50%;
    padding: 20px;
   
    margin:  0 auto 20px;
 }

 .serviceswrapper .card-box  .img img{
    display: block;
    margin-bottom: 0;
 }
 .serviceswrapper .card-box  .card h3{
    margin-bottom: 6px;
 }

 @media only screen and (max-width: 767px){
    .serviceswrapper .middle{
       flex-direction: column;
       gap: 10px;
    }
    .serviceswrapper .middle > div{
       flex: 0 1 auto;
    }
    .serviceswrapper .card-box  .card{
       flex: 0 1 44%;
       margin: auto;
    }
 
  }
  @media only screen and (max-width: 992px){
    .serviceswrapper .card-box  .card{
       flex: 0 1 100%;
       max-width: 282px;
    }
 
  }