.GrowYourBussionWrapper {

}

.GrowYourBussion-wrapper {
    padding: 30px 20px;
    margin: 0 -20px;
}
.gap20{
    gap:"20px"
}

.GrowYourBussion-wrapper .left {
    flex: 0 1 555px;
    border-radius: 50%;
    background: #F4FAFA;
}



.GrowYourBussion-wrapper .left img {
    
    height: 510px;
    width: 510px;
    padding: 77px;
}


.GrowYourBussion-wrapper .right {
    padding-top: 80px;
    flex: 1 1 424px;

}

.GrowYourBussion-wrapper .right h2 {
    margin-bottom: 10px;
}


.GrowYourBussion-wrapper .right p.h3 {
    font-size: 20px;
    margin-bottom: 30px;
}

.GrowYourBussion-wrapper .right p {
    text-align: justify;
    font-size: 15px;
}

.GrowYourBussion-wrapper .middle-wrapper {
    padding: 30px 0;
    text-align: center;
}

.GrowYourBussion-wrapper .middle-wrapper .heading-2 {
    margin: 30px 0;
}

.GrowYourBussion-wrapper .middle-wrapper .box {
    background: #19ACA5;
    box-shadow: 1px 1px 1px #0090CD2B;
    border: 1px solid #8B9CA83B;
    border-radius: 10px;
    padding: 22px 30px;
        width: 26%;

}

.GrowYourBussion-wrapper .middle-wrapper .box.box-4 {
    border-right: none;
}

.GrowYourBussion-wrapper .middle-wrapper .img {
    width: 75px;
    height: 75px;
    background-color: var(--GrowYourBussion-wrapper-img-bgColor);
    border-radius: 50%;
    margin: auto;
}

.GrowYourBussion-wrapper .middle-wrapper h2 {
    margin: 10px 0;
}
.top-wrapper{
   

    padding: 30px;
}


@media only screen and (max-width: 767px) {
    .GrowYourBussion-wrapper {
        padding-bottom: 20px;
    }

    .GrowYourBussion-wrapper .top-wrapper {
        flex-direction: column-reverse;

    }

    .GrowYourBussion-wrapper .top-wrapper>div {
        flex: 0 1 auto;
    }

    .GrowYourBussion-wrapper .middle-wrapper .center {
        flex-wrap: wrap;
        gap: 15px;
    }

    .GrowYourBussion-wrapper .middle-wrapper .box {
        width: 44%;
        border: none;
    }
}

@media only screen and (max-width: 480px) {
    .GrowYourBussion-wrapper .middle-wrapper .box {
        width: 90%;
        border: none;
    }
}