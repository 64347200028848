footer {
    background-color: var(--header-bg_color);
    color: var(--header-color);
    padding-top: 40px;
    font-size: 13px;
}

footer>div.footer {
    padding-bottom: 40px;
}

.footer {
    padding-top: 40px;
    padding-bottom: 80px;
    border-bottom: 1px solid var(--footer-border-color);
}

.footer a {
    color: var(--footer-border-link-color);
    display: block;
    margin: 4px 0;
    white-space: nowrap;
    transition: all .3s;

}

footer a:hover {
    color: var(--header-color);
}

.footer .logo {
    max-width: 255px;
}

.footer .logo img {
    margin-bottom: 25px;
}

.footer .menus {
    max-width: 767px;
}

.footer .menus h4 {
    margin-bottom: 20px;
}

.footer .menus h4 {
    margin-bottom: 20px;
}

.footer .downloadMeritPay>img {
    display: block;
    max-width: 135px;
    margin-left: 4px;
    margin-top: 10px;

}

.footer-botton {
    padding: 30px 0;
}

.footer-botton a {
    color: var(--footer-border-link-color);
}

footer .socal-icons div {
    width: 37px;
    height: 37px;
    background-color: var(--footer-socile-icon-bgColor);
    box-shadow: var(--footer-socile-icon-shadow);
    border-radius: 50%;
    font-size: 20px;
    margin-right: 10px;
}

footer .socal-icons div a {
    color: var(--header-color); 
}

footer .socal-icons div:hover a {
    color: var(--login-btn-bg);
   
}


.footer-contact-wrapper {
    padding: 20px 0;
    margin-top: -78px;
}

.footer-contact-wrapper .box {
    max-width: 300px;
    text-align: center;
    padding: 20px;
    transition: all .3s;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #8B9CA83B;
    border-radius: 10px;
}



.footer-contact-wrapper .box .img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--footer-contact-img-bgColor);
    margin: 0 auto 12px;
    box-shadow: var(--footer-img-shadow);
}

.footer-contact-wrapper .box h2 {
    color: var(--footer-contact-h2-color);
    margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
    .footerContactwrapper .footer-contact-wrapper {
       
    }

    .footer .logo {
        display: flex;
        max-width: 100%;
        gap: 20px;
    }


    .footer {
        flex-wrap: wrap;
    }

    .footer .menus {
        flex-wrap: wrap;
        gap: 20px;
    }

    .footerBottom {
        flex-direction: column;
    }

    .footerBottom>div {
        padding: 10px 0;
    }
} 