.contentwrapper {
    background-color: #fff;
    padding: 40px 0;
}

.conditions-heading {
    padding: 0px 0px 0px 100px;
}

.conditions {
    padding: 20px 100px 20px 100px;

}

.conditions::after {
    border: 0.5px solid #ab9dc5;
    content: "";
    display: block;
    margin-top: 20px;
    width: 100%;
}

.headings {
    font-size: 16px;
}

.heading-4 {
    font-size: 25px;
    font-weight: 600;
    color: #1F2857;
    line-height: 40px;

}