.faqwrapper {
    background-color: #fff;
    padding: 40px 20px;
}

.faq-heading {
    font-size: 18px;
    font-weight: 500;
    color: #1F2857;
    line-height: 40px;
    padding: 0px 120px 0px 120px;
}

.faq-container {
    padding: 10px 100px 0px 100px;
}

.faqs {

    padding: 0px 120px 0px 120px;
}

.faq-img {
    color: #808080;
}

.questions {
    margin: 0.5% 1% 2% 1%;
    font-size: 20px;
    color: #00baf2;
}

.answers {
    margin: 1% 1% 2% 3%;
    font-size: 20px;

}

.question-btn {
    border: none;
}