.contactuswrapper {
    background-color: #fff;
    padding: 30px 30px 115px 30px;
}

.containers {
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
}

.heading-contact {
    font-size: 25px;
    padding: 10px 0px;
    line-height: 40px;
}

.contactus {
    height: 450px;
    width: 1024px;
    position: relative;
}

.contactus iframe {
    border: none;
}

.contactusdetail {
    position: absolute;
    right: 55px;
    bottom: -75px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 25px #00000014;
}

.contactaddress {
    padding: 20px 30px 20px 30px;
}

.address {
    display: flex;
    gap: 5px;
}

.address label {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    padding: 0px 0px 5px 17px;
    letter-spacing: 0px;
}

.address img {
    height: 17px;
    width: 14px;
    margin-top: 6px;
}

.address p {
    font-size: 15px;
    letter-spacing: 0px;
    color: #000000;
}

.contact-img {
    color: #00baf2;
}

@media only screen and (max-width: 767px) {

    .consumerWrapper .consumer-top .contactus>div {
        max-width: 400px;
        margin: auto;
        flex: 0 1 auto;
    }

    .consumerWrapper .consumer-top>.desc {
        padding-top: 20px;
    }

    .consumerWrapper .consumer-top.consumer-middle {
        flex-direction: column;
    }

}